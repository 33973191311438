<template>
  <v-card class="mt-2">
    <!-- div da imagem -->
    <div class="d-flex justify-center">
      <v-img
        class="d-flex a-center rounded-circle mt-6"
        id="avatar"
        :src="img64str.length ? img64str : user.picture+$store.getters.getImgTokenQuery"
        refs="avatar"
        max-width="300"
        height="300"
      ></v-img>
    </div>
    <!-- botoes sobre a imagem -->
    <div class="d-flex justify-center">
      <!-- botão da camera -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            color="teal"
            x-small
            class="mt-n10 mx-1"
            @click="dialogCam = true"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </template>
        <span>Capturar da Câmera</span>
      </v-tooltip>
      <!-- upload da imagem -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            color="teal"
             x-small
            class="mt-n10 mx-1"
            @click="dialogImg = true"
          >
            <v-icon>mdi-file-image</v-icon>
          </v-btn>
        </template>
        <span>Enviar Arquivo</span>
      </v-tooltip>
    </div>
    <!-- div dos atributos editaveis -->
    <div class="d-flex justify-center">
      <v-card class="ma-2 w600">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title> Usuário do Sistema </v-list-item-title>
            <v-list-item-subtitle>{{ user.userName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <v-row>
                <v-col> Nome </v-col>
                <v-col cols="auto">
                  <v-btn
                    x-small
                    icon
                    @click="nameChange = true"
                    v-if="!nameChange"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="!nameChange">{{ user.name }}</span>
              <v-text-field
                v-else
                :rules="[
                  () => !!user.name || 'Campo obrigatório',
                  () => user.name.length > 4 || 'Minimo 5 caracteres',
                ]"
                hint="Alterar nome"
                v-model="user.name"
                append-icon="mdi-close"
                @click:append="nameChange = false"
              ></v-text-field>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <v-row>
                <v-col> Descrição </v-col>
                <v-col cols="auto">
                  <v-btn
                    x-small
                    icon
                    @click="descriptionChange = true"
                    v-if="!descriptionChange"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="!descriptionChange">{{ user.description }}</span>
              <v-text-field
                v-else
                hint="Alterar descrição"
                v-model="user.description"
                append-icon="mdi-close"
                @click:append="descriptionChange = false"
              ></v-text-field>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>
    <!-- div da troca de senha -->
    <div class="d-flex justify-center w600">
      <v-card class="ma-2 w600" elevation="3">
        <v-card-title> Trocar Senha: </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Senha Atual"
                :rules="[
                  () => !!senhaAtual || 'Campo obrigatório',
                  () => senhaAtual.length > 4 || 'Minimo 5 caracteres',
                ]"
                hint="Digite a sua senha atual"
                v-model="senhaAtual"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Senha"
                :rules="[
                  () => !!senha || 'Campo obrigatório',
                  () => senha.length > 4 || 'Minimo 5 caracteres',
                ]"
                hint="Digite a nova senha"
                v-model="senha"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Confirmação"
                hint="Confirme a nova senha"
                :rules="[
                  () => !!confirmacao || 'Campo obrigatório',
                  () => confirmacao.length > 4 || 'Minimo 5 caracteres',
                ]"
                v-model="confirmacao"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="update" color="primary"> Salvar </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>
    <!-- componente da webcam -->
    <Dialog
      @base64="img64($event)"
      @blob="picBlob = $event"
      class="mx-1"
      v-model="dialogCam"
    />
    <!-- componente do upload -->
    <DialogImg
      @base64="img64($event)"
      @blob="picBlob = $event"
      class="mx-1"
      v-model="dialogImg"
    />
    <!-- botão de salvar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          fixed
          bottom
          right
          @click="update"
          color="success"
        >
          <v-icon>mdi-content-save-edit</v-icon>
        </v-btn>
      </template>
      <span>Salvar Alterações</span>
    </v-tooltip>
  </v-card>
</template>

<script>
import Dialog from "./WebCam.vue";
import DialogImg from "./ImageUpload.vue";
export default {
  components: {
    Dialog,
    DialogImg
  },
  data: () => {
    return {
      dialogCam: false,
      dialogImg: false,
      picBlob: null,
      senhaAtual: "",
      senha: "",
      confirmacao: "",
      picture: "",
      name: "",
      nameChange: false,
      description: "",
      descriptionChange: false,
      img64str: "",
    };
  },
  methods: {
    img64(IMGbase64) {
      // this.user.picture = IMGbase64;
      this.img64str = IMGbase64;
    },
    update() {
      let bodyFormData = new FormData();
      bodyFormData.append("id", this.user.id);
      // caso venha uma nova foto
      if (this.picBlob != null) {
        bodyFormData.append("picture", this.picBlob);
      }
      // caso venha um nova descrição
      if (this.descriptionChange) {
        this.descriptionChange = false;
        bodyFormData.append("description", this.user.description);
      }
      // caso venha um nova descrição
      if (this.nameChange) {
        this.nameChange = false;
        bodyFormData.append("name", this.user.name);
      }
      // caso venha uma nova senha
      if (
        this.senhaAtual.length &&
        this.senha.length &&
        this.confirmacao.length
      ) {
        bodyFormData.append("oldPassword", this.senhaAtual);
        bodyFormData.append("newPassword", this.senha);
      }
      this.$http
        .put("myuser", bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.status < 300) {
            this.$store.dispatch("resetSnack");
            this.$store.dispatch(
              "showSuccessSnack",
              "Atualizado com sucesso! para entrar com a nova senha ou mudar a imagem do avatar, faça o login novamente"
            );
            this.$store.dispatch("loadMyUser");
            this.img64str = "";
          }
        });
    },
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getMyUser;
      },
    },
  },
  mounted() {
    this.$store.dispatch("loadMyUser");
  },
};
</script>

<style>
.w600 {
  min-width: 600px !important;
}
</style>