<template>
  <div class="text-center">
    <v-dialog v-model="value" width="500" persistent>
      <v-card>
        <v-card-title class="headline primary">
          <v-icon class="mr-2">mdi-upload</v-icon> Fazer upload
          <v-spacer></v-spacer>
          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-center">
            <div class="camera">
              <v-img
                :src="image"
                :max-width="wdth"
                :max-height="wdth * 0.75"
              ></v-img>
            </div>
          </div>
          <v-file-input
            truncate-length="21"
            label="Selecione uma imagem"
            accept="image/*"
            prepend-icon="mdi-image-search-outline"
            @change="onFileChange"
          ></v-file-input>
          <div class="d-flex justify-center">
            <v-btn color="primary" text @click="select" v-show="image">
              <v-icon class="mr-2">mdi-content-save-edit</v-icon> Usar esta
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    wdth: {
      type: Number,
      default: 450,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: null,
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    onFileChange(e) {
      if (e) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(e);
      } else {
        this.image = null;
      }
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    select(){
      if(this.image){
        this.$emit("base64", this.image);
        this.$emit("blob", this.dataURItoBlob(this.image));
        this.$emit("input", false);
      }
    }
  },
};
</script>

<style>
.camera {
  padding-top: 20px;
}
</style>