<template>
  <div class="text-center">
    <v-dialog v-model="value" width="500" persistent>
      <v-card>
        <v-card-title class="headline primary">
          <v-icon class="mr-2">mdi-camera</v-icon> Capturar
          <v-spacer></v-spacer>
          <v-btn icon @click="disableCamera"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          <div class="d-flex justify-center">
            <v-progress-circular
              v-show="isLoading"
              :size="50"
              color="primary"
              indeterminate
              class="ma-6"
            ></v-progress-circular>
            <div class="camera" v-show="!isLoading">
              <video
                ref="camera"
                :width="wdth"
                :height="wdth * 0.75"
                autoplay
              ></video>
            </div>
          </div>
          <div class="d-flex justify-center">
            <v-btn color="primary" text @click="takePhoto">
              <v-icon class="mr-2">mdi-camera-iris</v-icon> Capturar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    wdth: {
      type: Number,
      default: 450,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
    };
  },
  watch: {
    value() {
      if (this.value) {
        this.enableCamera();
      } else {
        this.disableCamera();
      }
    },
  },
  methods: {
    enableCamera() {
      this.isCameraOpen = true;
      this.createCameraElement();
    },
    disableCamera() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
      this.$emit("input", false);
    },
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      try {
        navigator.mediaDevices
          .getUserMedia({
            video: { width: { min: 640 }, height: { min: 480 } },
          })
          .then((stream) => {
            this.isLoading = false;
  
            //elemento camera
            this.$refs.camera.srcObject = stream;
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("input", false);
            alert(
              "Seu navegador não suporta Webcam ou seu Computador não possui"
            );
          });
        
      } catch (error) {
        this.isLoading = false;
            this.$emit("input", false);
            alert(
              "Seu navegador bloqueou a WebCam"
            );
      }

    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;
      this.$emit("image", this.$refs.camera);
      this.base64Gen();
      this.disableCamera();
    },
    base64Gen() {
      let canvas5 = document.createElement("canvas");
      canvas5.width = this.wdth;
      canvas5.height = this.wdth * 0.75;
      let ctx = canvas5.getContext("2d");
      ctx.drawImage(this.$refs.camera, 0, 0, canvas5.width, canvas5.height);

      let dataURI = canvas5.toDataURL("image/jpeg");

      this.isPhotoTaken = !this.isPhotoTaken;
      this.$emit("blob", this.dataURItoBlob(dataURI));
      this.$emit("base64", dataURI);
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
  },
};
</script>

<style>
.camera {
  padding-top: 20px;
}
</style>