var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-2"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{staticClass:"d-flex a-center rounded-circle mt-6",attrs:{"id":"avatar","src":_vm.img64str.length ? _vm.img64str : _vm.user.picture+_vm.$store.getters.getImgTokenQuery,"refs":"avatar","max-width":"300","height":"300"}})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n10 mx-1",attrs:{"fab":"","color":"teal","x-small":""},on:{"click":function($event){_vm.dialogCam = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-camera")])],1)]}}])},[_c('span',[_vm._v("Capturar da Câmera")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n10 mx-1",attrs:{"fab":"","color":"teal","x-small":""},on:{"click":function($event){_vm.dialogImg = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-image")])],1)]}}])},[_c('span',[_vm._v("Enviar Arquivo")])])],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"ma-2 w600"},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Usuário do Sistema ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.userName))])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',[_c('v-col',[_vm._v(" Nome ")]),_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.nameChange)?_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.nameChange = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)],1)],1),_c('v-list-item-subtitle',[(!_vm.nameChange)?_c('span',[_vm._v(_vm._s(_vm.user.name))]):_c('v-text-field',{attrs:{"rules":[
                function () { return !!_vm.user.name || 'Campo obrigatório'; },
                function () { return _vm.user.name.length > 4 || 'Minimo 5 caracteres'; } ],"hint":"Alterar nome","append-icon":"mdi-close"},on:{"click:append":function($event){_vm.nameChange = false}},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1)],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',[_c('v-col',[_vm._v(" Descrição ")]),_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.descriptionChange)?_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.descriptionChange = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)],1)],1),_c('v-list-item-subtitle',[(!_vm.descriptionChange)?_c('span',[_vm._v(_vm._s(_vm.user.description))]):_c('v-text-field',{attrs:{"hint":"Alterar descrição","append-icon":"mdi-close"},on:{"click:append":function($event){_vm.descriptionChange = false}},model:{value:(_vm.user.description),callback:function ($$v) {_vm.$set(_vm.user, "description", $$v)},expression:"user.description"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-center w600"},[_c('v-card',{staticClass:"ma-2 w600",attrs:{"elevation":"3"}},[_c('v-card-title',[_vm._v(" Trocar Senha: ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Senha Atual","rules":[
                function () { return !!_vm.senhaAtual || 'Campo obrigatório'; },
                function () { return _vm.senhaAtual.length > 4 || 'Minimo 5 caracteres'; } ],"hint":"Digite a sua senha atual"},model:{value:(_vm.senhaAtual),callback:function ($$v) {_vm.senhaAtual=$$v},expression:"senhaAtual"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Senha","rules":[
                function () { return !!_vm.senha || 'Campo obrigatório'; },
                function () { return _vm.senha.length > 4 || 'Minimo 5 caracteres'; } ],"hint":"Digite a nova senha"},model:{value:(_vm.senha),callback:function ($$v) {_vm.senha=$$v},expression:"senha"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Confirmação","hint":"Confirme a nova senha","rules":[
                function () { return !!_vm.confirmacao || 'Campo obrigatório'; },
                function () { return _vm.confirmacao.length > 4 || 'Minimo 5 caracteres'; } ]},model:{value:(_vm.confirmacao),callback:function ($$v) {_vm.confirmacao=$$v},expression:"confirmacao"}})],1)],1)],1)],1)],1),_c('Dialog',{staticClass:"mx-1",on:{"base64":function($event){return _vm.img64($event)},"blob":function($event){_vm.picBlob = $event}},model:{value:(_vm.dialogCam),callback:function ($$v) {_vm.dialogCam=$$v},expression:"dialogCam"}}),_c('DialogImg',{staticClass:"mx-1",on:{"base64":function($event){return _vm.img64($event)},"blob":function($event){_vm.picBlob = $event}},model:{value:(_vm.dialogImg),callback:function ($$v) {_vm.dialogImg=$$v},expression:"dialogImg"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","fixed":"","bottom":"","right":"","color":"success"},on:{"click":_vm.update}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-edit")])],1)]}}])},[_c('span',[_vm._v("Salvar Alterações")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }